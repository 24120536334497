.pagination-container{
    height: 40px;
    border-radius: 5px;
    display: flex;
    padding: 0;
}
.select{
    width: 80px;
}
// .pagination-container{
//     height:20px;
// }
// .contain-button{
//     height: 20px;
//     background-color: brown;
//     align-content: center;
//     margin: 0;
// }