.rounded{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: greenyellow;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    &:hover{
        background-color: grey;
        cursor: pointer;
    }
}
.container-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}