.my-delete:hover {
  color: red;
  cursor: pointer;
}
.container-button {
  display: flex;
  flex-direction: row;
}
.my-edit:hover {
  cursor: pointer;
  color: red;
}
.inputText {
  margin-bottom: 10px;
  width: 100%;
}
.dropZone {
  margin-top: 20px;
}
.snackbarWrapper {
  z-index: 1000;
}
