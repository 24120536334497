.rounded{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #9c27b0;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    color: white;
    &:hover{
        background-color: grey;
        cursor: pointer;
    }
}
.container-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.modal-data{
    z-index: 10000000 !important;
    margin-top: 50px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hover-in:hover{
    color: #9c27b0;
}